/* src/components/MapViewWithoutLogin.css */
.map-view-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* Aplica um leve blur e escurece o fundo para dar ênfase ao card de login */
    filter: blur(3px);
    opacity: 0.7;
    z-index: -1; /* Fica atrás dos elementos de login */
  }
  
  .map-view-container canvas {
    display: block;
    width: 100%;
    height: 100%;
    touch-action: none;
  }