.map-view {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  outline: none;
}

.map-view canvas {
  display: block;
  width: 100%;
  height: 100%;
  touch-action: none;
}

/* Header e botões */
.header {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 20;
}

.logout-button {
  background-color: #dc3545;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #c82333;
}

/* Diálogo de Pagamento - Centralizado */
.payment-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.payment-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.dialog-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dialog-button.cancel {
  background-color: #dc3545;
  color: #fff;
}

.dialog-button:not(.cancel) {
  background-color: #007bff;
  color: #fff;
}

/* Outros estilos */
.floating-key {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  animation: bounce 2s infinite;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@keyframes bounce {
  0%, 100% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-10px); }
}

.action-container {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;
}

.swatch-grid {
  display: grid;
  grid-template-columns: repeat(6, 30px);
  grid-template-rows: repeat(2, 30px);
  gap: 5px;
}

.swatch-button {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.color-picker-button {
  width: 65px;
  height: 65px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(135deg, #4e4e4e, #676767);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.edit-floating-button {
  width: 65px;
  height: 65px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  position: relative;
  transition: transform 0.3s ease;
}

.edit-floating-button.selection-mode {
  animation: expandContract 1.5s ease-in-out infinite;
}

@keyframes expandContract {
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
}

.select-button {
  height: 65px;
  padding: 0 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.btn-abrir-vestuario {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  z-index: 1100;
}

/* Toast */
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 1500;
}


/* ...outros estilos... */
/* src/components/MapView.css */

/* Ajuste se necessário */

/* Canvas etc... (seu CSS original) */

/* Botão Armário no canto inferior esquerdo */
.armario-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #333;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.2s ease;
  z-index: 1100;
}

.armario-button:hover {
  background-color: #444;
  transform: scale(1.05);
}

.armario-icon {
  color: #fff;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

/* Animação de clique: "pulsa" o ícone rapidamente */
.armario-button:active .armario-icon {
  transform: scale(1.4);
}

/* Toast (você já tinha) */
.toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 1500;
}

/* Demais CSS do PaymentDialog, floating-key, etc... */


.buy-skin-credits, .buy-pixels-coin {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: inline-block;
  margin: 10px;
}

.buy-skin-credits:hover, .buy-pixels-coin:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.buy-skin-credits:active, .buy-pixels-coin:active {
  transform: scale(0.95);
}