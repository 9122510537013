/* src/components/SkinPreview.css */

.skin-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2500;
    opacity: 0;
    transform: scale(0);
  }
  
  .skin-preview-modal.swirl-in {
    animation: swirl-in 0.4s forwards ease;
  }
  .skin-preview-modal.swirl-out {
    animation: swirl-out 0.4s forwards ease;
  }
  @keyframes swirl-in {
    0% {
      opacity: 0;
      transform: scale(0) rotate(0deg);
    }
    50% {
      opacity: 0.5;
      transform: scale(0.5) rotate(180deg);
    }
    100% {
      opacity: 1;
      transform: scale(1) rotate(360deg);
    }
  }
  @keyframes swirl-out {
    0% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
    50% {
      opacity: 0.5;
      transform: scale(0.5) rotate(180deg);
    }
    100% {
      opacity: 0;
      transform: scale(0) rotate(360deg);
    }
  }
  
  .skin-preview-dialog {
    background: #fff;
    border-radius: 12px;
    padding: 20px;
    width: 90%;
    max-width: 600px;
    position: relative;
    box-shadow: 0 8px 16px rgba(0,0,0,0.3);
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #f08080;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 18px;
  }
  .close-btn:hover {
    background: #e06666;
  }
  
  .preview-content {
    text-align: center;
    margin-top: 40px;
  }
  
  .preview-image {
    max-width: 100%;
    height: auto;
    border: 2px solid #ccc;
    border-radius: 8px;
  }