/* src/components/Notification.css */

.notification-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 2600;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .notification-item {
    background-color: #444;
    color: #fff;
    padding: 10px 14px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    animation: fade-slide-in 0.3s forwards;
  }
  
  @keyframes fade-slide-in {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .notification-item:hover {
    background-color: #555;
  }
  
  .notification-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .notification-text {
    flex-grow: 1;
    font-size: 14px;
  }
  
  .notification-close {
    background: transparent;
    color: #fff;
    border: none;
    font-size: 20px;
    line-height: 20px;
    cursor: pointer;
    margin-left: 8px;
  }
  .notification-close:hover {
    color: #ffcccc;
  }