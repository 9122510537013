/* Importa fonte pixelada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Container do modal */
.vestuario-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 0;
  transform: scale(0);
}

/* Animações swirl-in e swirl-out */
.vestuario-modal.swirl-in {
  animation: swirl-in 0.5s forwards ease;
}
.vestuario-modal.swirl-out {
  animation: swirl-out 0.5s forwards ease;
}
@keyframes swirl-in {
  0% { opacity: 0; transform: scale(0) rotate(0deg); }
  50% { opacity: 0.5; transform: scale(0.5) rotate(180deg); }
  100% { opacity: 1; transform: scale(1) rotate(360deg); }
}
@keyframes swirl-out {
  0% { opacity: 1; transform: scale(1) rotate(0deg); }
  50% { opacity: 0.5; transform: scale(0.5) rotate(180deg); }
  100% { opacity: 0; transform: scale(0) rotate(360deg); }
}

/* Caixa do diálogo */
.vestuario-dialog {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  width: 90%;
  max-width: 650px;
  position: relative;
  box-shadow: 0 8px 16px rgba(0,0,0,0.3);
  overflow: hidden;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Overlay de “Gerando skin…” */
.generating-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(250,250,250,0.9);
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.generating-content {
  text-align: center;
}
.loading-shirt-icon {
  animation: rotate-shirt 1.5s linear infinite;
}
@keyframes rotate-shirt {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Container interno para conteúdo com 80% de largura e 10% de margem */
.vestuario-inner {
  width: 90%;
  margin: 5% auto;
  text-align: center;
}

/* Título com fonte pixelada e animação */
.armario-title {
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-family: 'Press Start 2P', cursive;
  animation: title-animate 0.5s ease;
}
@keyframes title-animate {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* INVENTORY */
.inventory-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  max-height: 300px;
  overflow-y: auto;
  margin: 20px 0;
}
.inventory-grid::-webkit-scrollbar {
  width: 6px;
}
.inventory-grid::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}
.inventory-item {
  background-color: #fafafa;
  border-radius: 8px;
  padding: 12px;
  width: 110px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  opacity: 0;
  animation: fade-in 0.3s forwards ease;
}
.inventory-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0,0,0,0.2);
}
.inventory-item.selected {
  border: 2px solid #28a745;
}
@keyframes fade-in {
  to { opacity: 1; }
}

/* Imagens das skins */
.skin-image-wrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto 8px;
  overflow: hidden;
  border-radius: 8px;
  background-color: #ddd;
}
.skin-image-wrapper.preview {
  width: 128px;
  height: 128px;
}
.skin-preview {
  object-fit: contain;
  max-width: 100%;
}
.skin-name {
  font-weight: bold;
  font-size: 0.9rem;
  margin: 4px 0;
}
.equipped-label {
  color: #28a745;
  font-weight: bold;
  margin-top: 4px;
  font-size: 0.8rem;
}

/* Formulários */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.form-group textarea {
  width: 100%;
  padding: 8px;
  resize: vertical;
  border: 1px solid #aaa;
  border-radius: 4px;
}

/* Tags */
.tag-group {
  text-align: left;
}
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 6px;
}
.tag-btn {
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s, transform 0.2s;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.tag-btn .tag-icon {
  font-size: 1rem;
}
.tag-btn .check-icon {
  font-size: 0.9rem;
}
.tag-btn:hover {
  background-color: #e8e8e8;
}
.tag-btn.active {
  background-color: #28a745;
  color: #fff;
  border-color: #28a745;
  font-weight: 800;
  transform: scale(1.05);
}

/* Botões diversos */
.btn-create-skin,
.btn-gerar,
.btn-vestir {
  padding: 10px 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.2s ease;
}
.btn-create-skin .icon,
.btn-gerar .icon {
  margin-right: 6px;
  font-size: 1.2rem;
}
.btn-create-skin {
  background-color: #ff5722;
  color: #fff;
  margin-top: 20px;
}
.btn-create-skin:hover {
  background-color: #e64a19;
}
.btn-vestir {
  background-color: #28a745;
  color: #fff;
  padding: 10px 16px;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 8px;
}
.btn-vestir:hover {
  background-color: #218838;
}
.btn-gerar {
  background-color: #007bff;
  color: #fff;
  margin-top: 20px;
}
.btn-gerar:hover {
  background-color: #0056b3;
}

/* Erros */
.error {
  color: red;
  margin-top: 8px;
  font-weight: 600;
}

/* Rodapé do Modal: 80% de largura, centralizado; 3 botões com proporção 20-60-20 e 30% maior altura */
.modal-footer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  z-index: 101;
  margin-top: 20px;
}
.btn-footer {
  flex: 0 0 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  min-height: 60px; /* Aumenta a altura em cerca de 30% */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-footer.primary {
  flex: 0 0 60%;
  background-color: #007bff;
  color: #fff;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.1rem;
}
.btn-footer.primary:hover {
  background-color: #0056b3;
}
.btn-footer.back {
  background-color: #ffc107;
  color: #333;
}
.btn-footer.back:hover {
  background-color: #e0a800;
}
.btn-footer.close {
  background-color: #f08080;
  color: #fff;
}
.btn-footer.close:hover {
  background-color: #e06666;
}
.footer-icon {
  font-size: 1.2rem;
}

/* Estilos para o ícone primário (spinner + lâmpada) */
.primary-icon-container {
  position: relative;
  display: inline-block;
  width: 60px;   /* Define o tamanho do container do spinner */

}

.spinner-icon {
  font-size: 3rem;
  margin-bottom: 4px;
  transform: rotate(134deg);
}


.spinner-icon.animate {
  /* Quando loading for true, anima do offset 67° até 67° + 360° */
  animation: spinner-rotate 1s linear infinite;
  
}

@keyframes spinner-rotate {
  from {
    transform: rotate(67deg);
  }
  to {
    transform: rotate(427deg);  /* 67 + 360 */
  }
}

.lightbulb-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.choice-group {
  margin-bottom: 15px;
  text-align: left;
}

/* Container das escolhas: layout em coluna com scroll vertical */
.choices-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;
  max-height: 300px;   /* ajuste conforme necessário */
  overflow-y: auto;    /* scroll vertical */
  padding-right: 5px;  /* para evitar que o conteúdo fique encoberto pela barra de scroll */
}

/* Scroller personalizado em pixel art */
.choices-container::-webkit-scrollbar {
  width: 12px;
}

.choices-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 10px;
}

.choices-container::-webkit-scrollbar-thumb {
  background: #888;
  border: 2px solid #555;
  border-radius: 10px;
  background-image: linear-gradient(45deg, #888 25%, transparent 25%, transparent 50%, #888 50%, #888 75%, transparent 75%, transparent);
}

.choices-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*  <label className="tag-title"><strong>#</strong> Tags:</label> add pixel font and estilize title */
.tag-title {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.tag-title-menor{
  font-family: 'Press Start 2P', cursive;
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* const renderCreateView = () => (
  <div className="vestuario-inner">
    <h2 className="armario-title">Criar Nova Skin</h2>
    <div className="form-group">
      <label className='tag-title-menor'>Descrição da Skin:</label>
      <textarea
        rows={4}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Descreva aqui os detalhes da sua skin..."
      />
    </div>
    
    <div className="form-group choice-group">
      <label className="tag-title"><strong></strong>#TAGS</label>
      <div className="choices-container">
        {availableTags.map(choice => (
          <CardChoice 
            key={choice.id}
            choice={choice}
            stars={choice.stars}
            selected={selectedTagChoices.includes(choice.id)}
            onClick={() => toggleTagChoice(choice.id)}
          />
        ))}
      </div>
    </div>

    <div className="form-group choice-group">
      <label className="tag-title"><strong></strong>#Expressões</label>
      <div className="choices-container">
        {availableExpressions.map(choice => (
          <button
            key={choice.id}
            className={`choice-button ${selectedExpressionChoice === choice.id ? 'selected' : ''}`}
            onClick={() => selectExpressionChoice(choice.id)}
          >
            {choice.icon}
            <span>{choice.title}</span>
          </button>
        ))}
      </div>
    </div>
    
    {purchaseError && <p className="error">{purchaseError}</p>}
  </div>
); */
/* Container for expressions */
.choices-container-expressions {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px 0;
  overflow: hidden; /* Disable scroll */
}

/* Expression button styles */
.choice-button-expressions {
  width: 3em; /* Define a fixed width */
  height: 3em; /* Define a fixed height */
  border-radius: 50%; /* Make the button a circle */
  border: 2px solid #ccc;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s, transform 0.2s;
  font-size: 1.2rem;
  position: relative;
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.choice-button-expressions span {
  display: none; /* Hide text inside the button */
}

.choice-button-expressions:hover {
  background-color: #e8e8e8;
}

.choice-button-expressions.selected {
  background-color: #28a745;
  color: #fff;
  border-color: #28a745;
  transform: scale(1.1) rotate(360deg);
  animation: pulse 0.5s ease, rotate-expand 1s ease;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes rotate-expand {
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.1) rotate(180deg); }
  100% { transform: scale(1.1) rotate(360deg); }
}

/* Custom scrollbar for horizontal scrolling */
.choices-container::-webkit-scrollbar {
  height: 8px;
}

.choices-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.icon-expression {
  font-size: 3rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
}
/* <span className="hover-text">{choice.title}</span> */
/* Tooltip que segue o mouse */
.tooltip {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  pointer-events: none;
  z-index: 1000;
  white-space: nowrap;
  transform: translate(-50%, -120%);
}

/* Estilo para o ícone de '?' dentro do tooltip */
.tooltip-icon {
  margin-right: 5px;
  font-weight: bold;
}