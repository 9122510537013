/* src/components/StatusBar.css */
.statusbar-container {
  position: fixed;
  top: 20px;              /* Ajuste a distância do topo conforme necessário */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #444; /* Changed background color for better contrast */
  color: #eee; /* Changed text color for better readability */
  padding: 12px 24px; /* Adjusted padding for better spacing */
  border-radius: 12px; /* Slightly more rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4); /* Enhanced shadow for better depth */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  animation: slideDown 1s ease-out;
}

@keyframes slideDown {
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

.statusbar-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin: 0 15px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.statusbar-item:hover {
  transform: scale(1.1);
}

.statusbar-icon {
  margin-right: 8px;
  font-size: 24px;
  color: #ffdd57; /* Cor vibrante para os ícones */
}

.statusbar-item:hover .statusbar-icon {
  animation: iconPulse 1.5s infinite; /* Added animation to the icon on hover */
}

@keyframes iconPulse {
  0%, 100% {
    transform: scale(1);
    color: #ffdd57; /* Initial and final color */
  }
  50% {
    transform: scale(1.1);
    color: #ffab00; /* Intermediate color */
  }
}