.edit-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    z-index: 2000;
    max-width: 90%;
  }
  
  .color-palette {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .color-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .color-wheel-button {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .chrome-picker-container {
    margin-bottom: 10px;
  }
  
  .edit-modal-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .edit-modal-buttons button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background: #007BFF;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-modal-buttons button:hover {
    background: #0056b3;
  }