/* Container do card (botão em linha) */
.card-choice {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 8px;

    cursor: pointer;
    transition: transform 0.2s, border-color 0.2s;
    width: 95%;
    align-self: center;
    box-sizing: border-box;
    background-color: #f0f4f8; /* cor de fundo mais suave */
    max-height: 4em;
}
  
.card-choice.selected {
    background-color: #d0e8f2; /* azul claro suave */
    animation: splash 0.6s ease-out;
}

@keyframes splash {
    0% {
        box-shadow: 0 0 0 0 rgba(208, 232, 242, 0.7);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(208, 232, 242, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(208, 232, 242, 0);
    }
}
    
.card-choice:hover {
    transform: scale(1.01);
}
  
/* Área da esquerda: ocupa 75% do espaço */
.card-choice-main {
    display: flex;
    align-items: center;
    flex: 3;
}
  
.card-choice-icon {
    margin-right: 8px;
    font-size: 1.5rem;
}
  
.card-choice-info {
    text-align: left;
}
  
.card-choice-title {
    font-weight: bold;
    font-size: 1rem;
}
  
.card-choice-description {
    font-family: 'Press Start 2P', cursive;
    font-size: 0.6rem;
    margin-top: 4px;
}
  
/* Área da direita: ocupa 25% do espaço, com fundo diferenciado */
.card-choice-like-container {
    flex: 1;
    min-width: 50px;
    margin-right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4a4a4a; /* fundo cinza escuro */
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: background-color 0.2s;
    height: 4em;
}
  
.card-choice-like-container.liked {
    background-color: #dc0021; /* rosa claro */
}
  
.card-choice-like {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #ffffff; /* ícone branco */
    transition: color 0.2s;
}
  
/* Quando curtido, o ícone fica rosa escuro */
.card-choice-like-container.liked .card-choice-like {
    color: #ffffff;
    animation: expand-explode 0.6s ease-out;
}

@keyframes expand-explode {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(220, 0, 33, 0.7);
    }
    50% {
        transform: scale(2.4);
        box-shadow: 0 0 0 10px rgba(220, 0, 33, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(220, 0, 33, 0);
    }
}

/* <span className='stars'>{stars}</span> */
.stars {
    font-size: 0.9rem;
    margin-left: 0.5em;
}

/* Mobile styles */
@media (max-width: 600px) {
    .card-choice {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        max-height: none;
        width: 100%;
    }

    .card-choice-main {
        flex: 1;
        width: 100%;
    }

    .card-choice-like-container {
        width: 100%;
        margin-right: 0;
        margin-top: 8px;
        border-radius: 8px;
        height: auto;
        padding: 8px;
    }

    .card-choice-like {
        font-size: 1.5rem;
    }

    .card-choice-title {
        font-size: 1.2rem;
    }

    .card-choice-description {
        font-size: 0.8rem;
    }
}
