/* src/components/LoginPage.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #111;
}

.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Fundo: assume que MapViewWithoutLogin renderiza seu conteúdo dentro de um contêiner com a classe .map-view-container */
.map-view-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  filter: blur(5px);
  opacity: 0.6;
  z-index: -1;
}

/* Card central de login/registro */
.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 400px;
  background: linear-gradient(135deg, #ffffff, #f1f1f1);
  border-radius: 12px;
  padding: 40px 30px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 10;
  animation: slideDown 0.8s ease;
}

@keyframes slideDown {
  from { opacity: 0; transform: translate(-50%, -60%); }
  to { opacity: 1; transform: translate(-50%, -50%); }
}

/* Título estático */
.login-title {
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
}
.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

/* Fundo animado */
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-repeat: no-repeat;
  background-size: auto;
  animation: pan 90s linear infinite;
}

/* Keyframes para animar o background */
@keyframes pan {
  0% {
    background-position: left top;
  }
  25% {
    background-position: right top;
  }
  50% {
    background-position: right bottom;
  }
  75% {
    background-position: left bottom;
  }
  100% {
    background-position: left top;
  }
}

/* Resto dos estilos... */
/* Logo (estilo estático, sem animação) */
.logo {
  font-variant: small-caps;
  color: #007bff;
}

/* Formulário */
.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 90%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #007bff;
}

/* Botão de login/registro */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  transition: background 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Separador */
.separator {
  margin: 20px 0;
  font-size: 0.9rem;
  color: #aaa;
}

/* Botão de login com Google */
.google-button {
  width: 100%;
  padding: 12px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
}

.google-button:hover {
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.google-icon {
  margin-right: 8px;
}

/* Link para alternar entre login e registro */
.toggle-link p {
  margin-top: 15px;
  font-size: 0.9rem;
  color: #666;
}

.toggle-link p span {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

/* Mensagem de erro */
.error-message {
  color: #e74c3c;
  margin-bottom: 10px;
  font-weight: 600;
}