.my-skins {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255,255,255,0.95);
    padding: 15px;
    border-radius: 8px;
    z-index: 100;
    max-width: 300px;
  }
  
  .my-skins h3 {
    margin-top: 0;
  }
  
  .skins-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .skin-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
  }
  
  .skin-image {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-bottom: 5px;
  }
  
  .update-skin {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .update-skin input {
    padding: 5px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  /* Outros estilos já existentes para modal, toast, etc. */